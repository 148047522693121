<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

        <div class="backdrop" @click="close()"></div>

    <div>
      <nav :class="'navbar bg-transparent'">
        <div class="container-fluid">
          <div class="navbar-brand bars">
            <h4 class="mb-2 mt-2 ms-2">
              <span class="fw-bold text-dark">G</span>
              <span class="text-orange fw-bold">Sort</span>
            </h4>
          </div>

          <span class="navbar-left">
            <div class="navbar-brand me-2">
              <span style="display: -webkit-inline-box;">
                <h4 style="margin-top: 0.2rem">
                  <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                </h4>
              </span>
            </div> 

            <div class="navbar-brand dropdown">
              <span data-bs-toggle="dropdown" class="dropdown-toggle" style="display: -webkit-inline-box;">
                  <h4 style="margin-top: 0.2rem">
                  <i class="ph-user text-light me-3 nav-icon circle p-2 bg-default"></i>
                  </h4>
              </span>

              <div class="dropdown-menu">
                <span class="account">
                  <p class="account-text" style="display: inline-block">Hey, {{ user.name.split(' ')[0]  }}!</p>
                </span>
                
                <router-link :to="{name: 'Profile'}" class="dropdown-item">
                  <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                  <p class="dropdown-list"> My Account </p>
                </router-link>

                <router-link :to="{name: 'AdminUsers'}" class="dropdown-item">
                  <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                  <p class="dropdown-list"> Administration </p>
                </router-link>

                <div class="dropdown-divider"></div>

                <a class="dropdown-item logout" @click="logout()">
                  <font-awesome-icon class="icon logout" :icon="['fa', 'sign-out-alt']" />
                  <p class="dropdown-list"> Logout </p>
                </a>
              </div>
            </div>     
          </span>

          <div class="divider mt-4 d-none"></div>
        </div>
      </nav>
      
      <floating-nav :page="'DriverHome'"></floating-nav>

      <div class="body mb-4">
        <div class="container" style="padding: 1rem 2rem 0 2rem">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="text-orange fw-bold mb-0" v-if="user.name">Hi, {{ user.name.split(' ')[0] }}!</h2>
              <small class="text-muted fs-6">Choose a delivery you want to handle from this list</small>
            </div>          
          </div> 

          <div class="row mt-4">
            <no-data class="mt-4" v-if="!delivery_data.length" :message="'No new Orders'"></no-data>
            
            <status v-model:status_data=status_data></status>

            <span v-if="delivery_data.length">
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="({distance, price, delivery_from, delivery_to, driver_id, cart, id}, index) in delivery_data" :key="index">
                <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa">
                  <div class="card-body p-3">
                    <div class="row">
                      <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'>Order 00{{ id }} </h6>
                      <small>{{ cart.length }} items in cart</small>
                    </div>

                    <div class="row mt-3">
                      <div class="col">
                        <p> 
                          <font-awesome-icon class="text-dark me-2" :icon="['fa', 'circle-notch']"></font-awesome-icon> 
                          From <span class="text-capitalize">{{ delivery_from }}</span> </p>
                      </div>

                      <div class="row">
                        <p> <font-awesome-icon class="text-dark   me-2" :icon="['fa', 'location-arrow']"></font-awesome-icon> To <span class="text-capitalize">{{ delivery_to }}</span> </p>
                      </div>

                      <div class="col">
                        <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'road']"></font-awesome-icon> {{ distance/1000 }} KM</p>
                        
                        <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'money-bill-wave']"></font-awesome-icon> KSH. {{ price }} </p>
                      </div>
                    </div>                          

                    <div class="d-flex">
                      <div class="d-block ms-auto text-orange">
                        <button v-if="driver_id == 0" class="btn btn-transparent text-uppercase text-orange fw-bold" @click="deliverOrder(id)">
                          <p class="mb-0" style="font-size: 14px">
                          Deliver <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon> 
                          </p>
                        </button>

                        <button v-if="driver_id == user.id" class="btn btn-transparent text-uppercase text-success fw-bold">
                          <p class="mb-0" style="font-size: 14px">
                            Already delivering
                          </p>
                        </button>

                        <button v-if="driver_id != user.id && driver_id > 0" class="btn btn-transparent text-uppercase text-danger fw-bold">
                          <p class="mb-0" style="font-size: 14px">
                            Already owned by another driver
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
              <div class="card bg-transparent bg-gradient border-0">
                <div class="card-body"></div>
              </div>
            </div>          
          </div>                
        </div>
      </div>
    </div>     
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Sidebar from '../../components/AutoSidebar'
  import FloatingNav from '../../components/DriverFloatingNav';
  import NoData from '../../components/NoData'
  import Status from '../../components/Status'

  export default {
    name: 'DriverHome',
    components: {
      Sidebar,
      FloatingNav,
      NoData,
      Status
    },   
    data() {
      return {
        delivery_data: [],
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null, 
          "loading": false
        },        
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }
    },    
    mounted() {
      this.$store.dispatch("getDriver")
      this.getOrders()
    },
    methods: {
      deliverOrder(id) {
        let data = {
          status: 'dispatched',
          driver_id: this.user.id
        }

        this.axios.put('api/delivery/' + id, data).then(() => {
          this.status_data.loading = false
          this.status_data.success = true
          this.status_data.error = false
          this.status_data.success_message = 'Success!'

          setTimeout(() => {
            this.$router.push({name: 'DriverDelivery'})
          }, 1500);
        }).catch(errors => {
          this.status_data.loading = false
          this.status_data.success = false
          this.status_data.error = true
          this.status_data.success_message = errors.message
        })
      },
      getOrders() {
        this.axios.get('api/delivery/no_drivers').then(response => {
          this.delivery_data = response.data.data
        })        
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          localStorage.removeItem('user')
          this.$router.push({name: 'Login'});
        } else {
          
          // check if driver
          if( !this.user.driver) {
            // if not redirect to home
            this.$router.push({name: 'Home'});
          } else {
            this.$store.dispatch("changeLoading", false)
            this.getOrders()
          }

          this.$store.dispatch("changeLoading", false)
          this.getOrders()
        } 
      }
    }    
  }
</script>
