<template>
    <div class="position-fixed bottom-0 w-100 nav-bottom">
        <div class="row bg-dark m-2 rounded">
            
            <div :class="[{'active': page == link}, 'col text-center mt-2 mb-1']" @click="routeTo(link)" v-for="({name, link, icon}, index) in data" :key="index">
                <h4 class="mb-0">
                    <i :class="'ph-' + icon + ' text-default'"></i>
                </h4>
                
                <p class="mb-0 text-default">{{ name }}</p>
            </div>
        </div>
    </div>      
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_navbar.scss';
    .active {
        color: #fff!important;
        font-weight: 600;

        i {
            font-size: 1.25rem;
        }
    }

    .nav-bottom {
        border-top-left-radius: 80%; 
        padding: 0rem 2rem 0.5rem 2rem;
        border-top-right-radius: 80%;
        z-index: 1;

        .nav-item {
            cursor: pointer;
        }
    }
</style>

<script>
export default {
    name: 'DriverFloatingNav',
    props: {
        page: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            data: [
                {
                    name: 'Open Orders',
                    link: 'DriverHome',
                    icon: 'truck'
                },
                {
                    name: 'Delivery',
                    link: 'DriverDelivery',
                    icon: 'map-pin-line'
                }
            ]
        }
    },
    methods: {
        routeTo(value) {
          this.$router.push({name: value})
        }
    }
}

</script>